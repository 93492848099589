<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Sheep</h1>
        <p>
          In the very beginning we were given a few Merino sheep by a neighbor. Since then they have reproduced and
          there is a little flock now. Recently we have removed the rams in order to prevent the flock to grow any
          further.
        </p>
        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-sheep.jpg">
          <img src="../../assets/images/sheep.jpg">
        </picture>

        <p>
          Sheep do not fit well in our current phase of the project and so we keep the sheep in a separate area which
          they share with the horses. Once per year, before the summer heat sets in, we shear them and use the wool as
          mulch for trees.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "SheepEN",
  components: {
    Sidebar,
  },

};
</script>
